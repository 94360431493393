import React, { useEffect, useState } from 'react'
import { useInterval } from '../../utils'

export default function Title({ children, style, className, id, white }) {
  return (
    <div
      id={id}
      style={style}
      className={`text-xl lg:text-2xl font-bold text-center mt-2 mb-6 relative leading-tight ${className} ${white ? 'text-white' : 'text-gray-800'}`}>
      <div className="bg-orange h-1 w-3 lg:w-4 inline-block mr-3 align-middle rounded" />
      {children}
      <div className="bg-orange h-1 inline-block ml-3 align-middle rounded transition-all w-3 lg:w-4 ease-in-out" />
    </div>
  )
}
